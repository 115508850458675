import React, { useState } from "react";

import mailIcon from "../../assets/icons/mailIcon.png";
import userIcon from "../../assets/icons/User.png";
import logo from "../../assets/images/logo.png";

import {
  Typography,
  Grid,
  Button,
  OutlinedInput,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import cornerImage from "../../assets/images/corner.png";

import { useUserContext } from "contexts/UserContext";
import { postProfileUpdate } from "api/user";

export default function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sem, setSem] = useState(0);
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const nameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    setIsValidName(nameRegex.test(newName));
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const {
    state: { profile },
    dispatch,
  } = useUserContext();

  const handleSubmit = () => {
    if (!isValidName || !isValidEmail) {
      return;
    }

    const formData = {
      id: profile.id,
      name: name,
      email: email,
      sem: sem,
      branch: branch,
      college: college,
      token: profile.token,
    };
    console.log("Data being sent:", formData);
    postProfileUpdate(formData)
      .then((response) => {
        dispatch({ type: "SET_AUTH", payload: { isProfileAvail: true } });

        const updatedProfile = {
          ...profile,
          profileAvailable: true,
        };
        localStorage.setItem("response", JSON.stringify(updatedProfile));

        console.log("Form data submitted successfully:", response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  return (
    <Dialog open={true} disableEscapeKeyDown={true} fullWidth>
      <DialogContent
        sx={{
          width: "100%",
          maxHeight: "90vh",
          overflowY: "auto",
          padding: "20px",
          backgroundImage: `url(${cornerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding="20px"
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "24px" }}
              fontFamily="Inter"
            >
              Complete Your Profile
            </Typography>
            <FormControl variant="standard" sx={{ mt: 3, width: "100%" }}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "13px" }}
                fontFamily="Inter"
              >
                Full Name
              </Typography>
              <OutlinedInput
                value={name}
                onChange={handleNameChange}
                required
                sx={{
                  borderRadius: 4,
                  borderColor: "#F5F7F9",
                  mt: 1,
                  backgroundColor: "#F5F7F9",
                  "&:hover fieldset": {
                    borderColor: "green",
                  },
                }}
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ gap: 1 }}>
                    <img src={userIcon} />{" "}
                  </InputAdornment>
                }
                placeholder="Username"
              />

              <Typography
                sx={{ fontWeight: "500", fontSize: "13px", mt: 2 }}
                fontFamily="Inter"
              >
                Email ID
              </Typography>
              <OutlinedInput
                value={email}
                onChange={handleEmailChange}
                required
                sx={{
                  borderRadius: 4,
                  borderColor: "#F5F7F9",
                  mt: 1,
                  backgroundColor: "#F5F7F9",
                }}
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ gap: 1 }}>
                    <img src={mailIcon} />{" "}
                  </InputAdornment>
                }
                placeholder="Email ID"
              />

              <Typography sx={{ fontWeight: "500", fontSize: "13px", mt: 2 }}>
                Semester
              </Typography>
              <Select
                value={sem}
                onChange={(event) => setSem(event.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mt: 1, borderRadius: "15px" }}
              >
                <MenuItem disabled value={0}>
                  Select Semester{" "}
                </MenuItem>

                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>

              <Typography
                sx={{ fontWeight: "500", fontSize: "13px", mt: 2 }}
                fontFamily="Inter"
              >
                Branch
              </Typography>
              <Select
                value={branch}
                onChange={(event) => setBranch(event.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mt: 1, borderRadius: "15px" }}
              >
                <MenuItem disabled value={0}>
                  Select Branch
                </MenuItem>

                <MenuItem value={1}>Electronics and Communication</MenuItem>
                <MenuItem value={2}>Electrical and Electronics</MenuItem>
                <MenuItem value={4}>Computer Science</MenuItem>
                <MenuItem value={24}>Electronics</MenuItem>
                <MenuItem value={25}>Electrical</MenuItem>
                <MenuItem value={26}>Mechanical</MenuItem>
                <MenuItem value={27}>Civil</MenuItem>
                <MenuItem value={28}>Instrumentation</MenuItem>
                <MenuItem value={5}>Applied Electronics</MenuItem>
                <MenuItem value={6}>Biomedical</MenuItem>

                <MenuItem value={11}>Electronics & Biomedical</MenuItem>
              </Select>

              <Typography
                sx={{ fontWeight: "500", fontSize: "13px", mt: 2 }}
                fontFamily="Inter"
              >
                College
              </Typography>
              <OutlinedInput
                value={college}
                onChange={(event) => setCollege(event.target.value)}
                sx={{
                  borderRadius: 4,
                  borderColor: "#F5F7F9",
                  mt: 1,
                  backgroundColor: "#F5F7F9",
                }}
                fullWidth
                placeholder="College"
              />

              <Button
                onClick={handleSubmit}
                disabled={!isValidName || !isValidEmail}
                style={{
                  backgroundColor:
                    !isValidName || !isValidEmail ? "#b4b5b9" : "#43BA80",
                }}
                variant="outlined"
                sx={{
                  mt: 4,
                  py: 2,
                  width: "100%",
                  borderRadius: 4,
                  borderColor: "#B4B5B9",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
              >
                Continue
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
