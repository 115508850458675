import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from 'contexts/UserContext';
import routes from './routes';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { state: { isAuth } } = useUserContext();
    return (
        isAuth ? <Component {...rest} /> : <Navigate to={routes.Login} />
    );
};

export default ProtectedRoute;