import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Preloader from "components-shared/Preloader/Preloader";
import ProtectedRoute from "./ProtectedRoute";

import { useUserContext } from "contexts/UserContext";
import routes from "./routes";
import { makeUrl } from "utils/helpers";


const Home = lazy(() => import("component-pages/Home"));
const GateBundleCourse = lazy(() => import("component-pages/GateBundleCourse"));
const Announcement = lazy(() => import("component-pages/Announcements"));
const Branches = lazy(() => import("component-pages/Branches"));
const Subjects = lazy(() => import("component-pages/Subjects"));
const MyBundles = lazy(() => import("component-pages/MyBundles"));
const TestResults = lazy(() => import("component-pages/TestResults"));
const LoginOTP = lazy(() => import("component-pages/LoginOTP"))
const Login = lazy(() => import("component-pages/Login"));
const RazorpayPayment = lazy(() => import("component-pages/Subjects/components/razorpay"));
const TestResultsSubWise = lazy(() => import("component-pages/TestResultsSubWise"));

const GateBundle = lazy(() => import("component-pages/GateBundle"))


export default function AppRouter() {
  const { state: { isAuth } } = useUserContext();

  // const handleSubmissionSuccess = (success) => {
  //   if (success) {
  //     if (storedResponse) {
  //       setStoredResponse({ ...storedResponse, profileAvailable: true });
  //       localStorage.setItem(
  //         "response",
  //         JSON.stringify({ ...storedResponse, profileAvailable: true })
  //       );
  //     }
  //   }
  // };
  // const [storedResponse, setStoredResponse] = useState(null);
  // useEffect(() => {
  //   const storedResponse = JSON.parse(localStorage.getItem("response"));
  //   if (storedResponse) {
  //     setStoredResponse(storedResponse);
  //   }
  // }, []);

  const RouteList = [
    { path: routes.Login, element: isAuth ? <Navigate to="/" /> : <Login /> },
    { path: "/otp", element: isAuth ? <Navigate to="/" /> : <LoginOTP /> },

    { path: "/", element: <ProtectedRoute element={Home} /> },
    { path: routes.Announcement, element: <ProtectedRoute element={Announcement} /> },
    { path: routes.RazorPay, element: <ProtectedRoute element={RazorpayPayment} /> },
    { path: routes.MyCourses, element: <ProtectedRoute element={MyBundles} /> },
    { path: routes.TestResults, element: <ProtectedRoute element={TestResults} /> },
    { path: makeUrl(routes.GateBundle, "/:id"), element: <ProtectedRoute element={GateBundle} /> },

    { path: makeUrl(routes.GateBundleCourse, "/:id/*"), element: <ProtectedRoute element={GateBundleCourse} /> },
    // { path: makeUrl(routes.GateCourseLesson, "/:lessonId/:type/*"), element: <ProtectedRoute element={GateBundleCourse} /> },

    { path: makeUrl(routes.Subjects, "/:id"), element: <ProtectedRoute element={Subjects} /> },
    { path: makeUrl(routes.Branches, "/:id"), element: <ProtectedRoute element={Branches} /> },
    { path: makeUrl(routes.TestResultsSubWise, "/:subjectName"), element: <ProtectedRoute element={TestResultsSubWise} /> },

    { path: "/*", element: <Navigate to="/" /> }
  ];

  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        {RouteList.map(({ path, element }) => <Route key={path} path={path} element={element} />)}
      </Routes>
    </Suspense >
  );
}
