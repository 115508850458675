import React, { useEffect, } from "react";
import "./index.scss";

import { BrowserRouter, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import "react-toastify/dist/ReactToastify.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import AppRouter from "./AppRouter";
import Navbar from "components-layout/Navbar";
import Footer from "components-layout/Footer/Footer";
import Signup from "component-pages/SignupPopup/Signup";

import routes from "./routes";
import { AppContextProvider, } from "contexts/AppContext";
import { UserContextProvider, useUserContext } from "contexts/UserContext";
import Preloader from "components-shared/Preloader";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function AppWrapper() {
  const { state: { isAuth, isProfileAvail, isLoading } } = useUserContext();

  const location = useLocation();
  const noNavNFooter =
    location.pathname === routes.Signup ||
    location.pathname === routes.Login ||
    location.pathname === routes.Otp;


  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("response")
    window.location.reload();
  };

  return (
    <>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {!noNavNFooter && isProfileAvail && (
        <Navbar isAuthenticated={isAuth} onLogout={handleLogout} />
      )}

      {isLoading ?
        <Preloader />
        :
        <div className={"mainWrapper"}>
          <AppRouter />
        </div>
      }

      {isAuth && !isProfileAvail && <Signup />}

      {!noNavNFooter && isProfileAvail && <Footer />}
    </>
  );
}

function App() {
  return (
    <ErrorBoundary
      fallback={<div>Unexpected error occured. Please refresh</div>}
    >
      <AppContextProvider>
        <UserContextProvider>
          <BrowserRouter>
            <AppWrapper />
          </BrowserRouter>
        </UserContextProvider>
      </AppContextProvider>
    </ErrorBoundary>
  );
}

export default App;
