import { getUserProfile } from "api/user";
import { useEffect, useReducer, createContext, useContext } from "react";

export const UserContext = createContext();

const initialState = {
  isLoading: true,
  isAuth: false,
  isProfileAvail: false,
  profile: {},
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: payload };
    case "SET_PROFILE":
      return { ...state, profile: payload };
    case "SET_AUTH":
      return { ...state, ...payload };
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function setLoading(isLoading) {
    dispatch({ type: "SET_IS_LOADING", payload: isLoading });
  }

  const value = {
    state,
    dispatch,
  };

  useEffect(() => {
    const fetchUser = async () => {
      const storedResponseString = localStorage.getItem("response");

      /* No token found */
      if (!storedResponseString) {
        dispatch({ type: "SET_AUTH", payload: { isAuth: false } });
        setLoading(false);
        return;
      }

      /* Token found, hence logged in */
      dispatch({ type: "SET_AUTH", payload: { isAuth: true } });

      const storedResponse = JSON.parse(storedResponseString);
      const { id: student_id, token, profileAvailable } = storedResponse;

      /* New user, show sign up modal */
      if (!profileAvailable) {
        dispatch({ type: "SET_PROFILE", payload: { id: student_id, token } });
        dispatch({ type: "SET_AUTH", payload: { isProfileAvail: false } });
        setLoading(false);
        return;
      }

      /* Sign up complete, hide sign up modal */
      dispatch({ type: "SET_AUTH", payload: { isProfileAvail: true } });

      /* Get rest of user info */
      try {
        const response = await getUserProfile({ user_id: student_id, token });
        dispatch({
          type: "SET_PROFILE",
          payload: { ...response.data, id: student_id, token },
        });
      } catch (error) {
        console.error("Error fetching User:", error);
      } finally {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  return useContext(UserContext);
}
