import axios from "axios";
import config from "../config/config.js";

const BASE_URL = config.apiBaseUrl;
const TEST_SERIES_BASE_URL = config.testSeriesApiBaseUrl;

function getAuthToken() {
  return localStorage.getItem("token");
}

const Client = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

const TestSeriesClient = axios.create({
  baseURL: TEST_SERIES_BASE_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
})

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.clear();
      return (window.location.href = "/");
    }

    return Promise.reject(error);
  }
);

function getClient(apiServer) {
  switch (apiServer) {
    case "MAIN":
      return Client;
    case "TEST_SERIES":
      return TestSeriesClient
    default:
      return Client;
  }
}

export const getRequest = (path, query, opts) => {
  let { responseType, client } = opts || {};
  if (!responseType) responseType = "json"

  return getClient(client).get(`${path}`, {
    params: query,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const postRequest = (path, data, opts) => {
  let { client, query } = opts || {};

  return getClient(client).post(`${path}`, data, {
    params: query,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequestMultiPart = (path, data, opts) => {
  let { client } = opts || {};

  return getClient(client).post(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const patchRequest = (path, data, opts) => {
  let { client, } = opts || {};

  return getClient(client).patch(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const headRequest = (path, opts) => {
  let { client, } = opts || {};

  return getClient(client).head(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequest = (path, body, opts) => {
  let { client, } = opts || {};

  return getClient(client).delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  });
};
