import React, { useState } from "react";

import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo11.png";
import respLogo from "../../assets/logo/respLogo1.png";
import dpAvatar from "../../assets/avatars/dp.png";
import style from "./Navbar.module.scss";
import { useUserContext } from "contexts/UserContext";

import routes from "index/routes"

const MENU_LIST = [
  { text: "Home", href: "/" },
  { text: "My Courses", href: routes.MyCourses },
  { text: "Performance Assessment Results", href: routes.TestResults },
];

const SETTINGS = ["Logout"];

export default function Navbar({ onLogout, className, color }) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const matches = useMediaQuery("(max-width:900px)");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const { state: userState } = useUserContext();
  const { profile } = userState;


  return (
    <div className={style.wrapper}>
      <AppBar
        position="sticky"
        style={{
          display: "flex",
          padding: "1.5% 0%",
          justifyContent: "center",
          backgroundColor: "white",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            margin: { lg: "0 5%" },
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div
              className={style.navBarLogoSect}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <img
                  alt="msigma Logo"
                  src={matches ? respLogo : Logo}
                  width="100%"
                  height="auto"
                  className={style.logo}
                />
              </Link>
            </div>
          </Typography>


          {matches ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  backgroundColor: "#CDD2CF38",
                  padding: "5px 10px",
                  borderRadius: "25px",
                  "& .MuiTypography-root": {
                    color: "black",
                    marginLeft: "8px",
                  },
                  display: "flex",
                }}
              >
                <img
                  src={dpAvatar}
                  width="40px"
                  height="40px"
                  style={{ marginLeft: "-3px" }}
                />
                <Typography
                  fontWeight="500"
                  fontFamily="Manrope"
                  fontSize="16px"
                  color="black"
                >
                  {profile?.name}
                </Typography>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  sx: {
                    borderRadius: "0 0 18px  18px",
                    boxShadow: "0px 6px 24px 0px rgb(0 0 0 / 7%)",
                  },
                }}
              >
                {MENU_LIST.map((menu) => (
                  <MenuItem
                    key={menu.text}
                    component={Link}
                    to={menu.href}
                    onClick={handleCloseUserMenu}
                  >
                    {menu.text}
                  </MenuItem>
                ))}
                {SETTINGS.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      onLogout();
                      handleCloseUserMenu();
                    }}
                    sx={{ color: "#FF3333" }}
                  >
                    {setting}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <Typography
                component={Link}
                to="/"
                color="black"
                sx={{ mr: 6 }}
                fontWeight="500"
                fontFamily="Manrope"
                fontSize="16px"
              >
                Home
              </Typography> */}
              {MENU_LIST.map((menu) => (
                <Typography
                  key={menu.text}
                  component={Link}
                  to={menu.href}
                  color="black"
                  sx={{ mr: 6 }}
                  fontWeight="500"
                  fontFamily="Manrope"
                  fontSize="16px"
                  whiteSpace="nowrap"
                >
                  {menu.text}
                </Typography>
              ))}
              <Tooltip title="View More">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    backgroundColor: "#CDD2CF38",
                    padding: "5px 10px",
                    borderRadius: "25px",
                    "& .MuiTypography-root": {
                      color: "black",
                      marginLeft: "8px",
                    },
                    display: "flex",
                  }}
                >
                  <img
                    src={dpAvatar}
                    width="40px"
                    height="40px"
                    style={{ marginLeft: "-3px" }}
                  />
                  <Typography
                    fontWeight="500"
                    fontFamily="Manrope"
                    fontSize="16px"
                    color="black"
                  >
                    {profile?.name}
                  </Typography>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  sx: {
                    borderRadius: "0 0 18px  18px",
                    boxShadow: "0px 6px 24px 0px rgb(0 0 0 / 7%)",
                  },
                }}
              >
                {SETTINGS.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      onLogout();
                      handleCloseUserMenu();
                    }}
                    sx={{ color: "#FF3333" }}
                  >
                    {setting}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

