import { getRequest, postRequest } from "./_setup";
import config from "config/config";

const { apiBtechSuffixV3 } = config;

export function getUserProfile(query) {
    return getRequest(`/${apiBtechSuffixV3}/profile/`, query)
}

export function postProfileUpdate(body) {
    return postRequest(`/${apiBtechSuffixV3}/profile-update/`, body)
}

