import hostnames from "./hostnames.json";
import hostnamesUrlMap from "./hostnamesUrlMap.json";
import testSeriesUrlMap from "./testSeriesUrlMap.json";

function removeWWWFromURL(url) {
  if (url.startsWith("www.")) return url.substring(4);
  return url;
}

const { hostname } = window.location;
const hostnameWithoutWWW = removeWWWFromURL(hostname);

const HostnameApiUrlMap = {};
HostnameApiUrlMap[hostnames.LOCAL] = hostnamesUrlMap.LOCAL;
HostnameApiUrlMap[hostnames.DEV] = hostnamesUrlMap.DEV;
HostnameApiUrlMap[hostnames.PROD] = hostnamesUrlMap.PROD;

const TestSeriesUrlMap = {};
TestSeriesUrlMap[hostnames.LOCAL] = testSeriesUrlMap.LOCAL;
TestSeriesUrlMap[hostnames.DEV] = testSeriesUrlMap.DEV;
TestSeriesUrlMap[hostnames.PROD] = testSeriesUrlMap.PROD;

const apiBaseUrl = HostnameApiUrlMap[hostnameWithoutWWW];
const testSeriesUrl = TestSeriesUrlMap[hostnameWithoutWWW];

const config = {
  apiBaseUrl,
  apiBtechSuffixV2: `btech/v2`,
  apiBtechSuffixV3: `btech/v3`,
  testSeriesUrl,
  testSeriesApiBaseUrl: `https://exams.mgate.in`,
  testSeriesPortalBaseUrl: `https://testseries.mgate.in/tests/result/`,
};

export default config;
