const routes = {
  Home: "/",
  Login: "/login",
  Signup: "/signup",
  Otp: "/otp",
  Branches: "/branches",
  GateBundle: "/gate/bundle",
  GateBundleCourse: "/gate/bundle/course",
  // GateCourseLesson: "/gate/bundle/course/lesson",
  RazorPay: "/razorpay",
  TestResultsSubWise: "/results",
  Announcement: "/announcements",
  Subjects: "/subjects",
  MyCourses: "/my-bundles",
  TestResults: "/test-results",
};

export default routes;