import React from "react";

import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function Preloader() {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ paddingBlock: "10em" }}>
      <CircularProgress />
    </Grid>
  )
}
